import React from 'react'
import styled from 'styled-components'
// import Settings from '../Settings'
import { RowBetween } from '../Row'

const StyledSwapHeader = styled.div`
  align-items: center;
  align-self: stretch;
  background-color: #1e1e26;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #8f8fa6;
  border-left-style: none;
  border-right-style: none;
  border-top-style: none;
  display: flex;
  height: auto;
  justify-content: space-between;
  padding: 20px 24px;
  position: relative;
`

const StyledText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 5px;
  color: #9ee972;
`

export default function SwapHeader() {
  return (
    <StyledSwapHeader>
      <RowBetween>
        <StyledText>SWAP</StyledText>
        {/* <Settings /> */}
      </RowBetween>
    </StyledSwapHeader>
  )
}
