// import { transparentize } from 'polished'
import React from 'react'
import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
  css,
  DefaultTheme
} from 'styled-components'
import { Text, TextProps } from 'rebass'
import { Colors } from './styled'
// import BackgroundImage from '../assets/images/BG.png'
// import BgImg from '../assets/images/background.png'

export * from './components'

const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `
    return accumulator
  },
  {}
) as any

const white = '#FFFFFF'
const black = '#000000'

export function colors(): Colors {
  return {
    // base
    white,
    black,

    // text
    text1: '#9EE972',
    text2: '#9EE972',
    text3: '#FFFFFF',
    text4: '#868686',
    text5: '#2C2F36',

    // backgrounds / greys
    bg1: '#1E1E26',
    bg2: '#1E1E26',
    bg3: '#1E1E26',
    bg4: '#1E1E26',
    bg5: '#1E1E26',
    bg6: '#0E0E13',

    //specialty colors
    modalBG: 'rgba(0,0,0,.425)',
    advancedBG: 'rgba(0,0,0,0.1)',

    //primary colors
    primary1: '#9EE972',
    primary2: '#9EE972',
    primary3: '#9EE972',
    primary4: '#007050',
    primary5: '#007050',
    primary6: '#FFFFFF',
    primary7: '#8F8FA6',

    // color text
    primaryText1: '#000',

    // secondary colors
    secondary1: '#eeeeee',
    secondary2: '#17000b26',
    secondary3: '#17000b26',

    // other
    red1: '#FD4040',
    red2: '#F82D3A',
    red3: '#D60000',
    green1: '#9EE972',
    yellow1: '#FFE270',
    yellow2: '#F3841E',
    blue1: '#eeeeee',
    black2: '#1E1E26'
    // dont wanna forget these blue yet
    // blue4: '#153d6f70',
    // blue5: '#153d6f70',
  }
}

export function theme(): DefaultTheme {
  return {
    ...colors(),

    grids: {
      sm: 8,
      md: 12,
      lg: 24
    },

    //shadows
    shadow1: '#000',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `
  }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  return <StyledComponentsThemeProvider theme={theme()}>{children}</StyledComponentsThemeProvider>
}

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: #ffffff;
`

export const TYPE = {
  main(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text2'} {...props} />
  },
  link(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
  },
  black(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text1'} {...props} />
  },
  white(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'white'} {...props} />
  },
  body(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={16} color={'text1'} {...props} />
  },
  largeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />
  },
  mediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={20} {...props} />
  },
  subHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} {...props} />
  },
  small(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={11} {...props} />
  },
  blue(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'blue1'} {...props} />
  },
  yellow(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'yellow1'} {...props} />
  },
  darkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text3'} {...props} />
  },
  gray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg3'} {...props} />
  },
  italic(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={12} fontStyle={'italic'} color={'text2'} {...props} />
  },
  error({ error, ...props }: { error: boolean } & TextProps) {
    return <TextWrapper fontWeight={500} color={error ? 'red1' : 'text2'} {...props} />
  }
}

export const FixedGlobalStyle = createGlobalStyle`
html, input, textarea, button {
  font-family: 'Inter', sans-serif;
  font-display: fallback;
}
@supports (font-variation-settings: normal) {
  html, input, textarea, button {
    font-family: 'Inter var', sans-serif;
  }
}

html,
body {
  margin: 0;
  padding: 0;
}

 a {
   color: ${colors().blue1}; 
 }

* {
  box-sizing: border-box;
}

button {
  user-select: none;
}

html {
  font-size: 16px;
  font-variant: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-feature-settings: 'ss01' on, 'ss02' on, 'cv01' on, 'cv03' on;
  background-repeat: no-repeat;
  background: #1E1E1E;
}
`

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};
  background: #1B1C20; 
  // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #1E1E1E 100%);
  // filter: blur(100px);
  -webkit-background-size: auto;
  -moz-background-size: auto;
  -o-background-size: auto;
  background-size: auto;
  font-family: "Inter",Helvetica;
}

body {
  min-height: 100vh;
}

@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

@import url("https://fonts.googleapis.com/css?family=Roboto+Mono:400,700,500");


@font-face {
  font-family: "Gordita-Medium";
  font-style: normal;
  font-weight: 500;
  src: url('https://anima-uploads.s3.amazonaws.com/5afb04939b3bf9000d6c0724/Gordita-Medium.otf') format("opentype");
}

@font-face {
  font-family: "Clash Display-Bold";
  font-style: normal;
  font-weight: 700;
  src: url('https://anima-uploads.s3.amazonaws.com/projects/6096fad1c430c8f241249310/fonts/clashdisplay-bold.woff2');
}

@font-face {
  font-family: "Gordita-Bold";
  font-style: normal;
  font-weight: 700;
  src: url('https://anima-uploads.s3.amazonaws.com/5afb04939b3bf9000d6c0724/Gordita-Bold.otf') format("opentype");
}

@font-face {
  font-family: "Gordita-Regular";
  font-style: normal;
  font-weight: 400;
  src: url('https://anima-uploads.s3.amazonaws.com/5afb04939b3bf9000d6c0724/Gordita-Regular.otf') format("opentype");
}
`
